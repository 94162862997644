import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { MultiOutSelect } from '../FormWidgets/Select.js';

import { useTranslation } from 'react-i18next';
import { LoadSettingsDialog } from '../Utils/load_settings';

import { MultiOutHeadingEditable } from '../FormWidgets/MultiOutLabel';
import { SettingGroup, ValueBox } from '../FormWidgets/SettingGroup';

import { SettingActions } from '../components/index.js';

import { makeMultiOutObject } from '../Utils/utils.js';

import _ from 'lodash';

import { settingControls, useSavedSettings, writeTempSettings } from './settingFunctions';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.innerContainer2.background
  }
}));

export default function MultiOutSettings({ controller, parent, ...other }) {
  let { t } = useTranslation();
  let sid = 'multi_out'; // tab id is "outputs"

  let multi_outs = makeMultiOutObject(controller, parent);
  const [values, setValues] = useState(controller.type >= 6000 ? multi_outs : parent.settings[sid]);
  const controls = settingControls(controller, parent, sid, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(
    parent,
    controls,
    sid
  );

  const saveSelectIndustrial = (slave, port_id, event, value) => {
    let v = event.target.value;
    let vv = { ...values };
    vv[slave][port_id].func = v;
    setValues(vv);
    controls.saveTempSettings(`multi_out_${slave}_${port_id}_func`, v);
  };

  const saveSelectGrowbase = (index, event, value) => {
    let id = `out_${index + 1}_func`;
    let v = event.target.value;
    setValues({ ...values, [id]: v });
    controls.saveTempSettings(id, v);
  };

  const saveMemoIndustrial = (slave, port_id, event, value) => {
    //let id = Number (port_id.match (/out_([0-9]*)_memo/)[1]);
    let vv = { ...values };
    vv[slave][port_id].memo = value;
    setValues(vv);
    controls.saveTempSettings(`multi_out_${slave}_${port_id}_memo`, value);
  };

  const saveMemoGrowbase = (id, event, value) => {
    setValues({ ...values, [id]: value });
    controls.saveTempSettings(id, value);
  };

  if (controller.type >= 6000) {
    controls.restoreTempSettings = () => {
      let _values = { ...values };
      for (const [k, v] of Object.entries(parent.temp_settings[sid])) {
        for (let field of ['func', 'memo']) {
          let match = k.match(new RegExp(`multi_out_([0-9]+)_([0-9]+)_${field}`));
          if (match) {
            let slave = match[1];
            let port = match[2];
            _values[slave][port][field] = v;
          }
        }
        setValues(_values);
      }
    };

    controls.resetSettings = () => {
      setValues(_.cloneDeep(multi_outs));
      delete parent.temp_settings[sid];
      writeTempSettings(parent);
    };
  }

  const classes = useStyles();

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroup label={t('multifunctional_outputs_heading')}>
        {controller.type === 6000 ? (
          <>
            {Object.keys(values).map((slave) => (
              <div
                className={classes.box}
                style={{ padding: '1em', width: '100%', marginBottom: '0.5em' }}
              >
                <span style={{ fontSize: '1.5em' }}>
                  {' '}
                  Slave: <span style={{ fontFamily: 'monospace' }}>0x{slave.toString(16)}</span>
                </span>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {Object.values(values[slave]).map((v) => (
                    <ValueBox>
                      <MultiOutHeadingEditable
                        index={Number(v.id)}
                        memo={v.memo ?? ''}
                        handleInput={(id, event, value) =>
                          saveMemoIndustrial(slave, v.id, event, value)
                        }
                        multi_out={v}
                      />
                      <MultiOutSelect
                        value={v.func ?? ''}
                        type={v.type ?? ''}
                        name={Number(v.id)}
                        onChange={(event, value) => saveSelectIndustrial(slave, v.id, event, value)}
                        controller={controller}
                        parent={parent}
                        port={v}
                      />
                    </ValueBox>
                  ))}
                </div>
              </div>
            ))}
          </>
        ) : (
          <>
            {multi_outs.map((multi_out_index, i) => (
              <ValueBox>
                <MultiOutHeadingEditable
                  index={i + 1}
                  memo={values[`out_${i + 1}_memo`] ?? ''}
                  handleInput={saveMemoGrowbase}
                />
                <MultiOutSelect
                  value={values[`out_${i + 1}_func`] ?? ''}
                  type={values[`out_${i + 1}_type`] ?? ''}
                  name={multi_out_index[0]}
                  controller={controller}
                  onChange={(event, value) => saveSelectGrowbase(i, event, value)}
                />
              </ValueBox>
            ))}
          </>
        )}
      </SettingGroup>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}
