import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChatIcon from '@material-ui/icons/Chat';
import InfoIcon from '@material-ui/icons/Info';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckIcon from '@material-ui/icons/Check';

import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { RemoveMessageDuplicates, PrintDate } from '../Utils/utils';
import { getMessageButtonStyle } from '../Styles/styles.js';
import '../Styles/styles.css';

import getStateManager from '../StateManager.js';

function Alert({ type, message, ...props }) {
  if (type === 'warning')
    return <ReportProblemIcon style={{ color: 'orange', marginRight: '0.5em' }} />;
  else if (type === 'error')
    return <ReportProblemIcon style={{ color: 'red', marginRight: '0.5em' }} />;
  else if (type === 'success')
    return <CheckIcon style={{ color: 'green', marginRight: '0.5em' }} />;
  else return <InfoIcon style={{ color: 'blue', marginRight: '0.5em' }} />;
}

function MessageInfo({ controller, message }) {
  return (
    <>
      <b>{controller}</b> - <i>{message}</i>
    </>
  );
}

function MessageEntry({ message, index }) {
  return (
    <ListItem key={'message_entry_' + index}>
      <ListItemIcon>
        <Alert type={message.type} />
      </ListItemIcon>
      <ListItemText
        primary={<MessageInfo controller={message.controller} message={message.message} />}
        secondary={<small>({PrintDate(message.timestamp, 'l LTS')})</small>}
      />
    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    maxHeight: '40vh'
  }
}));

export default function MessagesModal({ logged_in, ...other }) {
  let { t } = useTranslation();
  const classes = useStyles();

  const stateManager = getStateManager();
  const messages = stateManager.store().messages;

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    messages.unreadMessages = false;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const clearLog = () => {
    stateManager.wsManager.sendWSMsg(null, 'clear_msg_log');
  };

  const messageButton = getMessageButtonStyle(false, messages.unreadMessages);

  if (logged_in) {
    return (
      <div style={{ zIndex: 2 }}>
        <ChatIcon style={messageButton} onClick={handleOpen}></ChatIcon>
        <Dialog
          open={open}
          onClose={handleClose}
          classes={{ paper: classes.dialogPaper }}
          fullWidth={true}
          maxWidth="sm"
          aria-labelledby="simple-modal-title"
        >
          <DialogTitle id="form-dialog-message-title">Messages</DialogTitle>
          <DialogContent>
            <List>
              {[...messages.messages].reverse().map((message, i) => (
                <MessageEntry message={message} index={i} />
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={clearLog} color="primary">
              {t('clear_log')}
            </Button>
            <Button onClick={handleClose} color="primary">
              {t('close')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <></>;
  }
}
