import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MuiSwitch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Collapse } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import _ from 'lodash';

import { parse, stringify } from 'yaml';

import { HandleAction } from '../Utils/utils.js';

import { ShowSetting } from '../FormWidgets/ShowSliderSetting';
import {
  SettingGroup,
  SettingGroupBlock,
  SettingGroupGrid,
  ValueBox
} from '../FormWidgets/SettingGroup';
import { LoadSettingsDialog } from '../Utils/load_settings';
import {
  AbsoluteRelativeSelect,
  DayWeekSelect,
  DayWeekToggle,
  EnableDisableDaytimeSelect,
  EnableDayTimeDisableSelect,
  EventGapSelect
} from '../FormWidgets/Select.js';
import { MyTimePicker } from '../FormWidgets/DatePicker.js';
import { EditableLabel } from '../FormWidgets/EditableLabel.js';
import Desc from '../FormWidgets/Description';

import { NUM_PHASES } from '../Constants/constants.js';

import getStateManager from '../StateManager.js';

import { settingControls, useSavedSettings, writeTempSettings } from './settingFunctions';

import { SettingActions } from '../components';
import { MakePhase } from '../Utils/simulation_utils.js';

function GetDays(index, values) {
  const arr = Array.from(
    { length: NUM_PHASES },
    (_, i) => values['len_phase_' + (i + 1) + '_days']
  );
  if (index === 0) return 0;
  else return arr.slice(0, index).reduce((acc, cur) => acc + cur, 0);
}

function BuildPhaseId(phase_num, field_id) {
  return phase_num + ':' + field_id;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

export default function Scheduling({ controller, parent, ...other }) {
  let { t } = useTranslation();
  const tab_id = 'schedule_meta';
  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog: openMetaDialog, closeLoadSettingsDialog: closeMetaDialog } =
    useSavedSettings(parent, controls, tab_id);
  const stateManager = getStateManager();

  const [useWeekDay, setUseWeekDay] = useState(
    Array.from({ length: NUM_PHASES }, (_, i) => values['len_phase_' + (i + 1) + '_days'] > 31)
  );
  controls.restoreTempSettings = () => {
    const new_values = _.merge(
      _.cloneDeep(parent.settings[tab_id]),
      _.cloneDeep(parent.temp_settings[tab_id])
    );
    setValues(new_values);
    // This is necessary to setup whether weeks or days was saved.
    setUseWeekDay(
      Array.from(
        { length: NUM_PHASES },
        (_, i) => new_values['len_phase_' + (i + 1) + '_days'] > 31
      )
    );
  };

  const callAction = (type, value) => {
    if (parent.connection.status !== 'bad') {
      HandleAction(stateManager.wsManager, parent, type, value);
    }
  };

  const removeNonExistingPhases = (phases) => {
    return phases.filter((obj) => {
      return '_exists' in obj;
    });
  };

  const tab_phases_id = 'schedule_values';
  const [phases, setPhases] = useState(JSON.parse(JSON.stringify(parent.settings[tab_phases_id])));
  if (parent.temp_settings[tab_phases_id] === undefined) {
    console.log('[saved] initialized temp settings, num phases: ', phases.length);
    parent.temp_settings[tab_phases_id] = Array.from(
      { length: NUM_PHASES },
      (_, i) => new Object()
    );
    for (let i = 0; i < phases.length; i++)
      parent.temp_settings[tab_phases_id][i] = { _exists: true };
    console.log('[saved] initialized temp settings, num phases: ', parent.temp_settings);
  }
  const phasesControls = settingControls(controller, parent, tab_phases_id, phases, setPhases);
  const { openLoadSettingsDialog: openPhaseDialog, closeLoadSettingsDialog: closePhaseDialog } =
    useSavedSettings(parent, phasesControls, tab_phases_id, () => {
      // filter phases not existing:
      let temp = removeNonExistingPhases(parent.temp_settings[tab_phases_id]);
      // filter unchanged phases:
      let empty_temp = temp.filter (p => {
					let c =_.cloneDeep (p);
					if (c._exists) delete c._exists;
					return !_.isEmpty (c);
				 });
      return !_.isEmpty(empty_temp) && !_.isEqual(parent.settings[tab_phases_id], temp);
    });
  phasesControls.restoreTempSettings = () => {
    let temp = removeNonExistingPhases(parent.temp_settings[tab_phases_id]);
    console.log('[saved] restoreTempSettings (custom): ', temp, parent.settings[tab_phases_id]);
    setPhases(
      removeNonExistingPhases(_.merge(_.cloneDeep(parent.settings[tab_phases_id]), _.cloneDeep(temp)))
    );
  };

  const ToggleDayWeek = (index, value) => {
    // Update use week/day array:.
    let updatedPhasesDays = useWeekDay;
    useWeekDay[index] = value;
    setUseWeekDay(updatedPhasesDays);
    // modify number of days matching days/ weeks
    const id = 'len_phase_' + (index + 1) + '_days';
    // Update value in schedule-meta-struct
    controls.setValue(id, values[id] * (value ? 7 : 1 / 7));
  };

  const reset = () => {
    controls.resetSettings();
    phasesControls.resetSettings();
  };

  const save = () => {
    controls.saveSettings();
    phasesControls.saveSettings();
  };

  const addPhase = () => {
    const phase_id = phases.length + 1;
    phases.push(MakePhase({ name_phase_short: 'Your Name', phase_id: phase_id }));
    controls.setValue('active_phase_cnt', values.active_phase_cnt + 1);
    setPhases(phases);
    // Fill up controller-values with detault values.
    parent.temp_settings[tab_phases_id][phase_id - 1] = MakePhase({
      name_phase_short: 'Your Name',
      phase_id: phase_id
    });
    parent.temp_settings[tab_phases_id][phase_id - 1]['_exists'] = true;
  };

  const delPhase = (index) => {
    // Empty phases and reduce number of active phases
    controls.setValue('active_phase_cnt', values.active_phase_cnt - 1);
    phases[index] = {};
    parent.temp_settings[tab_phases_id][index] = {}; // update entry in temp settings
    // Move phases "up"
    const updatedUseWeekday = useWeekDay;
    const updatedValues = values;
    for (let i = index; i < phases.length - 1; i++) {
      phases[i] = phases[i + 1]; // move phases "up"
      phases[i]['phase_id'] = i + 1; // update phase-id
      phases[i + 1] = {}; // empty moved phase
      // Update day lengths
      const day_len = values['len_phase_' + (i + 2) + '_days'];
      controls.setValue('len_phase_' + (i + 1) + '_days', day_len); // use to store in temp-settings
      updatedValues['len_phase_' + (i + 1) + '_days'] = day_len;  // use to update all values
      useWeekDay[i] = day_len > 31;
      // update entry in temp settings: merge phases-i (since phases-i+1 is now
      // phase-i) with temp_settings-i+1 (to keep any updates applied to
      // temp_settings-i+1), then add _exists flag.
      const merged = _.merge(
        _.cloneDeep(phases[i], _.cloneDeep(parent.temp_settings[tab_phases_id][i + 1]))
      );
      if ('_exists' in parent.temp_settings[tab_phases_id][i + 1]) merged['_exists'] = true;
      parent.temp_settings[tab_phases_id][i] = _.cloneDeep(merged);
      parent.temp_settings[tab_phases_id][i + 1] = {}; // empty moved phase
    }
    setValues(updatedValues);
    setPhases(
      phases.filter((obj) => {
        return Object.keys(obj).length > 0;
      })
    ); // remove empty objects
    setUseWeekDay(updatedUseWeekday);
    writeTempSettings(parent);
  };

  const [dayRange, setDayRange] = useState(Array.from({ length: NUM_PHASES }, (_, i) => '???'));
  useEffect(() => {
    const dayRange = Array.from(
      { length: NUM_PHASES },
      (_, i) =>
        `${GetDays(i, values) + 1} - ${
          GetDays(i, values) + values['len_phase_' + (i + 1) + '_days']
        }`
    );
    setDayRange(dayRange);
  }, [values]);

  const GetControllerValues = (phase) => {
    if (parent.settings[tab_phases_id].length > phase) return parent.settings[tab_phases_id][phase];
    else return MakePhase({ phase_id: phase });
  };

  const [expand, setExpand] = useState(Array.from({ length: NUM_PHASES }, (_, i) => false));
  const handleExpandClick = (i) => {
    let modifiedExpand = expand;
    modifiedExpand[i] = !expand[i];
    setExpand(modifiedExpand);
  };

  const download = () => {
    const jsonObj = {};
    jsonObj[tab_id] = values;
    jsonObj[tab_phases_id] = phases;
    const yaml = stringify(jsonObj);
    console.log('YAML: ', yaml);
    // Create a Blob from the YAML string
    const blob = new Blob([yaml], { type: 'text/yaml' });
    // Create a link element and set the download attribute with a filename
    const link = document.createElement('a');
    link.download = values.name_short + '.yaml';
    // Create a URL for the Blob and set it as the href attribute
    link.href = URL.createObjectURL(blob);
    // Programmatically click the link to trigger the download
    link.click();
    // Clean up the URL object
    URL.revokeObjectURL(link.href);
  };

  const [yamlContent, setYamlContent] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setYamlContent(e.target.result);
      };
      reader.readAsText(file);
    }
  };
  const upload = () => {
    if (yamlContent) {
      try {
        // Parse the YAML content to a JSON object
        const jsonObject = parse(yamlContent);
        console.log('Parsed yml: ', jsonObject); // Output the JSON object to the console or handle it as needed
        setValues(jsonObject[tab_id]);
        setPhases(jsonObject[tab_phases_id]);
        // Fill up controller-values with detault values.
        if (parent.settings[tab_phases_id].length < phases.length) {
          for (let i = parent.settings[tab_phases_id].length + 1; i <= phases.length; i++) {
            parent.settings[tab_phases_id].push(
              MakePhase({ name_phase_short: 'Your Name', phase_id: i })
            );
          }
        }
      } catch (error) {
        console.error('Error parsing YAML:', error);
      }
    } else {
      alert('No File selected.');
    }
  };

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openMetaDialog}
        handleClose={closeMetaDialog}
        controls={controls}
        info="scheduling meta"
      />
      <LoadSettingsDialog
        open={openPhaseDialog}
        handleClose={closePhaseDialog}
        controls={phasesControls}
        info="scheduling phases"
      />

      <SettingActions onReset={reset} onSave={save} />

      <SettingGroupGrid controller={controller} id={'save_and_load'}>
        <ValueBox controller={controller} id={"scheduling_name_short"}>
          <EditableLabel
            label={t('current_schedule_short_name')}
            id={'name_short'}
            value={values.name_short}
            handleInput={(id, event, value) => controls.setValue(id, value)}
          />
        </ValueBox>
        <>
          <Button color="primary" onClick={download}>
            {t('download_current_schedule_to_file')}
          </Button>
          <br />
          <Button color="primary" onClick={upload}>
            {t('upload_schedule_from_file')}
          </Button>
          <input type="file" accept=".yaml,.yml" onChange={handleFileChange} />
        </>
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'phases'}>
        {phases.map((phase, i) => (
          <ValueBox>
            <Grid container>
              <Grid item xs={5}>
                <EditableLabel
                  label={t('phase') + ' ' + phase.phase_id}
                  id={'phase_' + phase.phase_id}
                  value={phase.name_phase_short}
                  handleInput={(phase_id, event, value) =>
                    phasesControls.setValue(BuildPhaseId(i, 'name_phase_short'), value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <DayWeekSelect
                  use_week={useWeekDay[i]}
                  value={values['len_phase_' + (i + 1) + '_days']}
                  onChange={(event, value) =>
                    controls.setValue('len_phase_' + (i + 1) + '_days', event.target.value)
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <DayWeekToggle
                  value={useWeekDay[i]}
                  onChange={(event, value) => ToggleDayWeek(i, event.target.value)}
                />
              </Grid>
              <Grid item xs={1}>
                <Button color="primary" onClick={() => delPhase(i)}>
                  <DeleteForeverIcon />
                </Button>
              </Grid>
            </Grid>
          </ValueBox>
        ))}
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <MuiSwitch
              checked={values.enable_smooth_change === 1}
              onClick={(event) => {
                controls.setValue('enable_smooth_change', event.target.checked ? 1 : 0);
              }}
            />
            <Desc id="enable_smooth_change" />
          </Typography>
        </ValueBox>
        <>
          <Button color="primary" onClick={addPhase}>
            {t('add_phase')}
          </Button>
          <br />
          <Button color="primary" onClick={() => callAction('action_current_day_in_grow_cycle')}>
            {t('reset_current_day_in_grow_cycle')}
          </Button>
        </>
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'selection'}>
        <>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <MuiSwitch
                checked={values.enable_scheduling === 1}
                onClick={(event) => {
                  controls.setValue('enable_scheduling', event.target.checked ? 1 : 0);
                }}
              />
              <Desc id="enable_scheduling" />
            </Typography>
          </ValueBox>
          <ValueBox controller={controller} id={"scheduling_"}>
            {[
              'enable_humidity',
              'enable_temper_main',
              'enable_vpd',
              'enable_heater',
              'enable_heating_mat',
              'enable_co2_concentr',
              'enable_co2_temper',
              'enable_day_night'
            ].map((id, i) => (
              <>
                <Typography gutterBottom>
                  <MuiSwitch
                    checked={values[id] === 1}
                    onClick={(event) => {
                      controls.setValue(id, event.target.checked ? 1 : 0);
                    }}
                  />
                  <Desc id={id} />
                </Typography>
              </>
            ))}
          </ValueBox>
        </>
        <>
          <ValueBox controller={controller} id={"scheduling_"}>
            {[
              'enable_interval_1',
              'enable_interval_2',
              'enable_event_1',
              'enable_event_2',
              'enable_event_3',
              'enable_event_4',
              'enable_event_5',
              'enable_event_6',
              'enable_event_7',
              'enable_event_8'
            ].map((id, i) => (
              <>
                <Typography gutterBottom>
                  <MuiSwitch
                    checked={values[id] === 1}
                    onClick={(event) => {
                      controls.setValue(id, event.target.checked ? 1 : 0);
                    }}
                  />
                  <Desc id={id} />
                </Typography>
              </>
            ))}
          </ValueBox>
        </>
      </SettingGroupGrid>

      {phases.map((phase, i) => (
        <SettingGroupGrid label={t('phase') + '_' + phase.phase_id} fullWidth={true}>
          <Grid container>
            <Grid item xs={2}>
              {phase.name_phase_short}
            </Grid>
            <Grid item xs={1}>
              Day: {dayRange[i]}
            </Grid>
            <Grid item xs={1}>
              Week: ???
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center', marginTop: '-5em' }}>
            <ExpandMore
              expand={expand[i]}
              onClick={() => handleExpandClick(i)}
              aria-expanded={expand[i]}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>

          <Collapse in={expand[i]} timeout="auto" unmountOnExit>
            <HumiditySettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <TempSettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <VpdSettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <HeaterSettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <HeatingMatSettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasescontrols={phasesControls}
              controller={controller}
            />

            <Co2ConcentrationSettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <Co2TemperSettings
              index={i}
              values={values}
              controller_values={GetControllerValues(i)}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <StartEndSettings
              index={i}
              values={values}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <Interval1Settings
              index={i}
              values={values}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            <Interval2Settings
              index={i}
              values={values}
              phase={phase}
              phasesControls={phasesControls}
              controller={controller}
            />

            {Array.from({ length: 8 }).map((elem, event_num) => (
              <EventSettings
                event_num={event_num + 1}
                index={i}
                values={values}
                phase={phase}
                phasesControls={phasesControls}
                controllerType={event_num > 4 ? controller.type : undefined}
              />
            ))}
          </Collapse>
        </SettingGroupGrid>
      ))}

      <SettingActions onReset={reset} onSave={save} />
    </React.Fragment>
  );
}

function HumiditySettings({ index, values, controller_values, phase, phasesControls, controller }) {
  if (values.enable_humidity) {
    return (
      <SettingGroupGrid controller={controller} id={'humidity_sp'}>
        <ShowSetting
          id={BuildPhaseId(index, 'rh_sp_day')}
          controller={controller}
          cur_value={phase.rh_sp_day}
          controller_value={controller_values.rh_sp_day}
          controls={phasesControls}
          type="float"
        />
        <ShowSetting
          id={BuildPhaseId(index, 'rh_sp_night')}
          controller={controller}
          cur_value={phase.rh_sp_night}
          controller_value={controller_values.rh_sp_night}
          controls={phasesControls}
          type="float"
        />
      </SettingGroupGrid>
    );
  } else {
    return <></>;
  }
}

function TempSettings({ index, values, controller_values, phase, phasesControls, controller }) {
  if (values.enable_temper_main) {
    return (
      <SettingGroupGrid controller={controller} id={'temp_sp'}>
        <ShowSetting
          id={BuildPhaseId(index, 'temp_main_sp_day')}
          controller={controller}
          cur_value={phase.temp_main_sp_day}
          controller_value={controller_values.temp_main_sp_day}
          controls={phasesControls}
          type="float"
        />
        <ShowSetting
          id={BuildPhaseId(index, 'temp_main_sp_night')}
          controller={controller}
          cur_value={phase.temp_main_sp_night}
          controller_value={controller_values.temp_main_sp_night}
          controls={phasesControls}
          type="float"
        />
      </SettingGroupGrid>
    );
  } else {
    return <></>;
  }
}

function VpdSettings({ index, values, controller_values, phase, phasesControls, controller }) {
  if (values.enable_vpd) {
    return (
      <SettingGroupGrid controller={controller} id={'vpd_sp'}>
        <ShowSetting
          id={BuildPhaseId(index, 'vpd_sp_day')}
          controller={controller}
          cur_value={phase.vpd_sp_day}
          controller_value={controller_values.vpd_sp_day}
          controls={phasesControls}
          type="float"
        />
        <ShowSetting
          id={BuildPhaseId(index, 'vpd_sp_night')}
          controller={controller}
          cur_value={phase.vpd_sp_night}
          controller_value={controller_values.vpd_sp_night}
          controls={phasesControls}
          type="float"
        />
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <Desc id="vpd_control_enable" name="VPD Calculation Enable" />
          </Typography>
          <EnableDisableDaytimeSelect
            value={phase.vpd_control_enable}
            name="vpd_control_enable"
            onChange={(event, value) =>
              phasesControls.setValue(BuildPhaseId(index, 'vpd_control_enable'), event.target.value)
            }
          />
        </ValueBox>
      </SettingGroupGrid>
    );
  } else {
    return <></>;
  }
}

function HeaterSettings({ index, values, controller_values, phase, phasesControls, controller }) {
  if (values.enable_heater) {
    return (
      <SettingGroupGrid controller={controller} id={'temp_sp_heater'}>
        <ShowSetting
          id={BuildPhaseId(index, 'temp_heater_sp_day')}
          controller={controller}
          cur_value={phase.temp_heater_sp_day}
          controller_value={controller_values.temp_heater_sp_day}
          max={phase.temp_main_sp_day / 10 - 1}
          controls={phasesControls}
          type="float"
          off_at={49}
        />
        <ShowSetting
          id={BuildPhaseId(index, 'temp_heater_sp_night')}
          controller={controller}
          cur_value={phase.temp_heater_sp_night}
          controller_value={controller_values.temp_heater_sp_night}
          max={phase.temp_main_sp_night / 10 - 1}
          controls={phasesControls}
          type="float"
        />
      </SettingGroupGrid>
    );
  } else {
    return <></>;
  }
}

function HeatingMatSettings({ index, values, controller_values, phase, phasesControls, controller }) {
  let { t } = useTranslation();

  if (values.enable_heating_mat) {
    return (
      <SettingGroupBlock label={t('temperature_setpoint_heating_mat')}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="toggle_temp_heating_mat_day" />
            </Typography>
            <AbsoluteRelativeSelect
              value={phase.heating_mat_day_rel0_abs1}
              name="toggle_temp_heating_mat_day"
              onChange={(event, value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'heating_mat_day_rel0_abs1'),
                  event.target.value
                )
              }
            />
          </ValueBox>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="toggle_temp_heating_mat_night" />
            </Typography>
            <AbsoluteRelativeSelect
              value={phase.heating_mat_night_rel0_abs1}
              name="toggle_temp_heating_mat_night"
              onChange={(event, value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'heating_mat_night_rel0_abs1'),
                  event.target.value
                )
              }
            />
          </ValueBox>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'stretch' }}>
          {phase.heating_mat_day_rel0_abs1 === 1 ? (
            <ShowSetting
              id={BuildPhaseId(index, 'temp_heating_mat_day_abs')}
              controller={controller}
              cur_value={phase.temp_heating_mat_day_abs}
              controller_value={controller_values.temp_heating_mat_day_abs}
              controls={phasesControls}
              type="float"
            />
          ) : (
            <ShowSetting
              id={BuildPhaseId(index, 'temp_heating_mat_day_rel')}
              controller={controller}
              cur_value={phase.temp_heating_mat_day_rel}
              controller_value={controller_values.temp_heating_mat_day_rel}
              controls={phasesControls}
              type="float"
            />
          )}
          {phase.heating_mat_night_rel0_abs1 === 1 ? (
            <ShowSetting
              id={BuildPhaseId(index, 'temp_heating_mat_night_abs')}
              controller={controller}
              cur_value={phase.temp_heating_mat_night_abs}
              controller_value={controller_values.temp_heating_mat_night_abs}
              controls={phasesControls}
              type="float"
            />
          ) : (
            <ShowSetting
              id={BuildPhaseId(index, 'temp_heating_mat_night_rel')}
              controller={controller}
              cur_value={phase.temp_heating_mat_night_rel}
              controller_value={controller_values.temp_heating_mat_night_rel}
              controls={phasesControls}
              type="float"
            />
          )}
        </Box>
      </SettingGroupBlock>
    );
  } else {
    return <></>;
  }
}

function Co2ConcentrationSettings({ index, values, controller_values, phase, phasesControls, controller }) {
  if (values.enable_co2_concentr) {
    return (
      <SettingGroupGrid controller={controller} id={'co2_concentration'}>
        <ShowSetting
          id={BuildPhaseId(index, 'co2_concentr_sp')}
          controller={controller}
          cur_value={phase.co2_concentr_sp}
          controller_value={controller_values.co2_concentr_sp}
          controls={phasesControls}
        />
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography>
            <Desc id="co2_control" />
          </Typography>
          <EnableDayTimeDisableSelect
            value={phase.co2_control_on_off}
            name="co2_control"
            onChange={(event) =>
              phasesControls.setValue(BuildPhaseId(index, 'co2_control_on_off'), event.target.value)
            }
          />
        </ValueBox>
      </SettingGroupGrid>
    );
  } else {
    return <></>;
  }
}

function Co2TemperSettings({
  index,
  values,
  controller_values,
  phase,
  phasesControls,
  controller
}) {
  if (values.enable_co2_temper) {
    return (
      <SettingGroupGrid controller={controller} id={'temperature_co2'}>
        <ShowSetting
          id={BuildPhaseId(index, 'temp_main_sp_co2')}
          controller={controller}
          cur_value={phase.temp_main_sp_co2}
          controller_value={controller_values.temp_main_sp_co2}
          controls={phasesControls}
          type="float"
        />

        <ShowSetting
          id={BuildPhaseId(index, 'temp_heater_sp_co2')}
          controller={controller}
          cur_value={phase.temp_heater_sp_co2}
          controller_value={controller_values.temp_heater_sp_co2}
          controls={phasesControls}
          max={Number.parseFloat((phase.temp_main_sp_co2 - 10) / 10)}
          type="float"
          off_at={49}
        />
      </SettingGroupGrid>
    );
  } else {
    return <></>;
  }
}

function StartEndSettings({ index, values, phase, phasesControls, controller }) {
  if (values.enable_day_night) {
    return (
      <SettingGroup controller={controller} id={'start_end_time'}>
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <Desc id="start_day" name="Start Day at" />
          </Typography>
          <MyTimePicker
            value={phase.time_day_start}
            seconds={true}
            name="start_day"
            onChange={(value) =>
              phasesControls.setValue(
                BuildPhaseId(index, 'time_day_start'),
                value.toLocaleTimeString('de-DE')
              )
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <Desc id="end_day" name="End Day at" />
          </Typography>
          <MyTimePicker
            value={phase.time_day_end}
            seconds={true}
            name="end_day"
            onChange={(value) =>
              phasesControls.setValue(
                BuildPhaseId(index, 'time_day_end'),
                value.toLocaleTimeString('de-DE')
              )
            }
          />
        </ValueBox>
      </SettingGroup>
    );
  } else {
    return <></>;
  }
}

function Interval1Settings({ index, values, phase, phasesControls, controller }) {
  if (values.enable_interval_1) {
    return (
      <>
        <SettingGroup controller={controller} id={'interval_day'}>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="interval_1_on_day" name="Interval On (Day)" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_1_on_day}
              seconds={true}
              name="interval_1_on_day"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_1_on_day'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="interval_1_off_day" name="Interval Off (day)" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_1_off_day}
              seconds={true}
              name="interval_1_off_day"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_1_off_day'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
        </SettingGroup>

        <SettingGroup controller={controller} id={'interval_night'}>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="interval_1_on_night" name="Interval On (Night)" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_1_on_night}
              seconds={true}
              name="interval_1_on_night"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_1_on_night'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="interval_1_off_night" name="Interval Off (night)" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_1_off_night}
              seconds={true}
              name="interval_1_off_night"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_1_off_night'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
        </SettingGroup>
      </>
    );
  } else {
    return <></>;
  }
}

function Interval2Settings({ index, values, phase, phasesControls, controller}) {
  if (values.enable_interval_2) {
    return (
      <>
        <SettingGroup controller={controller} id={'interval_2_day'}>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="time_interval_2_on_day" show="interval_2_on_day" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_2_on_day}
              seconds={true}
              name="interval_2_on_day"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_2_on_day'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="time_interval_2_off_day" show="interval_2_off_day" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_2_off_day}
              seconds={true}
              name="time_interval_2_off_day"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_2_off_day'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
        </SettingGroup>

        <SettingGroup controller={controller} id={'interval_2_night'}>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="time_interval_2_on_night" show="interval_2_on_night" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_2_on_night}
              seconds={true}
              name="time_interval_2_on_night"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_2_on_night'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
          <ValueBox controller={controller} id={"scheduling_"}>
            <Typography gutterBottom>
              <Desc id="time_interval_2_off_night" show="interval_2_off_night" />
            </Typography>
            <MyTimePicker
              value={phase.time_interval_2_off_night}
              seconds={true}
              name="time_interval_2_off_night"
              onChange={(value) =>
                phasesControls.setValue(
                  BuildPhaseId(index, 'time_interval_2_off_night'),
                  value.toLocaleTimeString('de-DE')
                )
              }
            />
          </ValueBox>
        </SettingGroup>
      </>
    );
  } else {
    return <></>;
  }
}

function EventSettings({ event_num, index, values, phase, phasesControls, controller}) {
  const xId = (pre, post) => {
    return pre + event_num + (post !== undefined ? post : '');
  };

  if (
    values[xId('enable_event_')] &&
    (controller.type === undefined || [5055, 6000].includes(controller.type))
  ) {
    return (
      <SettingGroup controller={controller} id={xId('event_')}>
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <Desc id={xId('time_event', '_start')} show="event_start" />
          </Typography>
          <MyTimePicker
            value={phase[xId('time_event', '_start')]}
            seconds={true}
            name={xId('event', '_start')}
            onChange={(value) =>
              phasesControls.setValue(
                BuildPhaseId(index, xId('time_event', '_start')),
                value.toLocaleTimeString('de-DE')
              )
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <Desc id={xId('time_event', '_duration')} show="event_duration" />
          </Typography>
          <MyTimePicker
            value={phase[xId('time_event', '_duration')]}
            seconds={true}
            name={xId('event', '_duration')}
            onChange={(value) =>
              phasesControls.setValue(
                BuildPhaseId(index, xId('time_event', '_duration')),
                value.toLocaleTimeString('de-DE')
              )
            }
          />
        </ValueBox>
        <ValueBox controller={controller} id={"scheduling_"}>
          <Typography gutterBottom>
            <Desc id={xId('event_', '_exec_gap')} show="event_exec_gap" />
          </Typography>
          <EventGapSelect
            value={phase[xId('event_', '_exec_gap')]}
            onChange={(event) =>
              phasesControls.setValue(
                BuildPhaseId(index, xId('event_', '_exec_gap')),
                event.target.value
              )
            }
          />
        </ValueBox>
      </SettingGroup>
    );
  } else {
    return <></>;
  }
}
