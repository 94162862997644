import React, { Children } from 'react';
import Typography from '@material-ui/core/Typography';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Desc from '../FormWidgets/Description';

import { makeStyles } from '@material-ui/core/styles';
import {
  controller_project_exclude_features as cpef,
  controller_type_exclude_features as ctef
} from '../Constants/ControllerFeatures';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    padding: '25px 15px',
    marginTop: '15px',
    backgroundColor: theme.palette.outerContainer.background,
    boxShadow: '3px 3px 2px ' + theme.palette.innerContainer.shadow
  },
  valuebox: {
    padding: '30px',
    paddingLeft: 'min(30px, 5%)',
    margin: '5px',
    flex: 1,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.innerContainer.background,
    color: theme.palette.fontcolor,
    boxShadow: '3px 3px 2px ' + theme.palette.innerContainer.shadow,
    [theme.breakpoints.down('md')]: {
      paddingRight: '5px'
    }
  }
}));

export function SettingGroup({ controller, id, label, children }) {
  let { t } = useTranslation();
  const classes = useStyles();
  // Check if ValueBox is excluded for this controller-type
  if (
    controller !== undefined &&
    controller.type in ctef &&
    ctef[controller.type]['headlines'].includes(id)
  )
    return null;
  if (
    controller !== undefined &&
    controller.project_id in cpef &&
    cpef[controller.project_id]['headlines'].includes(id)
  )
    return null;

  // Otherwise create and return content
  if (label === undefined) label = t(id);

  const childrenArray = Children.toArray(children);
  return (
    <Paper component={Box} className={classes.outerContainer}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <Desc id={label} />
      </Typography>

      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{childrenArray}</Box>
    </Paper>
  );
}

// used for co2, ventilation, lights, alarms, advanced
export function SettingGroupGrid({ controller, id, label, children, fullWidth }) {
  let { t } = useTranslation();
  const classes = useStyles();
  // Check if ValueBox is excluded for this controller-type
  if (
    controller !== undefined && 
    controller.type in ctef 
    && ctef[controller.type]['headlines'].includes(id)
  ) 
    return null;
  if (
    controller !== undefined &&
    controller.project_id in cpef &&
    cpef[controller.project_id]['headlines'].includes(id)
  )
    return null;

  // Otherwise create and return content
  if (label === undefined) label = t(id);

  const childrenArray = Children.toArray(children);
  return (
    <Paper component={Box} className={classes.outerContainer}>
      <Typography variant="h5" style={{ paddingBottom: 8 }}>
        <Desc id={label} />
      </Typography>
      <Grid container spacing={1} columns={{ xs: 6, md: 12 }}>
        {childrenArray.map((child) => (
          <Grid item xs={12} md={fullWidth ? 12 : 6}>
            {child}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

export function SettingGroupBlock({ label, children }) {
  const childrenArray = Children.toArray(children);
  const classes = useStyles();
  return (
    <Paper
      p={{ xs: 2, sm: 4 }}
      mt={{ xs: 2, sm: 4 }}
      component={Box}
      className={classes.outerContainer}
    >
      <Typography variant="h5">
        <Desc id={label} />
      </Typography>
      <Box>
        {childrenArray.map((child) => (
          <Box> {child} </Box>
        ))}
      </Box>
    </Paper>
  );
}

export function ValueBox({ style, children, controller, id }) {
  const classes = useStyles();
  // Check if ValueBox is excluded for this controller-type
  if (
    controller !== undefined &&
    controller.type in ctef &&
    ctef[controller.type]['values'].includes(id)
  )
    return null;
  if (
    controller !== undefined &&
    controller.project_id in cpef &&
    cpef[controller.project_id]['values'].includes(id)
  )
    return null;
  // Otherwise create and return content
  const childrenArray = Children.toArray(children);
  return (
    <Paper className={classes.valuebox} style={style}>
      {childrenArray}
    </Paper>
  );
}
