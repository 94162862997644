import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { useTranslation } from 'react-i18next';
import { languageMap } from '../LanguageSelect';
import { TokenTypeSelect } from '../FormWidgets/Select';
import { useAddress } from '../Hooks/addressHook';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSnackbar } from 'notistack';
import { useAuth } from '../Hooks/authHook';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '2rem',
    marginLeft: '3rem',
    marginRight: '3rem',
    backgroundColor: theme.palette.background.paper
  }
}));

export default function AccountPage({ user, setUser }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const address = useAddress();

  const [api_tokens, setApiTokens] = React.useState([]);

  React.useEffect(() => {
    if (!address.localMode) {
      fetch('api/api_token_infos')
        .then((response) => response.json())
        .then((data) => setApiTokens(data))
        .catch((error) => enqueueSnackbar(error, { variant: 'error' }));
    }
  }, []);

  const [open_email_dialog, setOpenEmailDialog] = React.useState(false);
  const openEmailDialog = () => {
    setOpenEmailDialog(true);
  };
  const closeEmailDialog = () => {
    setOpenEmailDialog(false);
  };

  const [open_language_dialog, setOpenLanguageDialog] = React.useState(false);
  const openLanguageDialog = () => {
    setOpenLanguageDialog(true);
  };
  const closeLanguageDialog = () => {
    setOpenLanguageDialog(false);
  };

  const [open_delete_dialog, setOpenDeleteDialog] = React.useState(false);
  const openDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const closeDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const [open_password_dialog, setOpenPasswordDialog] = React.useState(false);
  const openPasswordDialog = () => {
    setOpenPasswordDialog(true);
  };
  const closePasswordDialog = () => {
    setOpenPasswordDialog(false);
  };

  const [tokenDialogData, setTokenDialogData] = React.useState({ req_type: '', req: '' });
  const [tokenDialogPayload, setTokenDialogPayload] = React.useState({});

  const [open_token_dialog, setOpenTokenDialog] = React.useState(false);
  const openTokenDialog = () => {
    setOpenTokenDialog(true);
  };
  const closeTokenDialog = () => {
    setOpenTokenDialog(false);
  };

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <EmailDialog
          open={open_email_dialog}
          handleClose={closeEmailDialog}
          user={user}
          setUser={setUser}
        />
        <LanguageDialog open={open_language_dialog} handleClose={closeLanguageDialog} />
        <PasswordDialog open={open_password_dialog} handleClose={closePasswordDialog} />
        <DeleteDialog open={open_delete_dialog} handleClose={closeDeleteDialog} user={user} />
        <TokenDialog
          open={open_token_dialog}
          handleClose={closeTokenDialog}
          params={tokenDialogData}
          payload={tokenDialogPayload}
        />
        <React.Fragment>
          <Typography variant="h4" gutterBottom>
            {t('my_account')}
          </Typography>
          <Grid container spacing={7}>
            <Grid item xs={6}>
              <Typography gutterBottom>{t('username')}</Typography>
              <label name="username">
                <i>{user.name}</i>
              </label>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>E-Mail</Typography>
              <label name="email">
                <i>{user.email !== '' ? user.email : t('not_set')}</i>
              </label>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{t('account_language')}</Typography>
              <label name="account-language">
                <i>{user.language !== '' ? t(user.language) : t('not_set')}</i>
              </label>
            </Grid>
          </Grid>

          {!address.localMode && (
            <Button onClick={openEmailDialog} color="primary">
              {t('change_email')}
            </Button>
          )}
          <Button onClick={openLanguageDialog} color="primary">
            {t('save_current_language')}
          </Button>

          <Button onClick={openPasswordDialog} color="primary">
            {t('change_password')}
          </Button>

          <Button onClick={openDeleteDialog} color="secondary">
            {t('delete_account')}
          </Button>
        </React.Fragment>
      </Container>

      {!address.localMode && (
        <Container className={classes.root}>
          <React.Fragment>
            <Typography variant="h5" gutterBottom>
              API TOKENS
            </Typography>
            <Grid container spacing={7}>
              <Grid item xs={6}>
                {api_tokens.length == 0 && <Typography gutterBottom>{t('no-tokens')}</Typography>}
                {api_tokens.length > 0 &&
                  api_tokens.map((token_type, index) => (
                    <>
                      <Typography gutterBottom>
                        Token Type: <i>{token_type}</i>
                      </Typography>
                      <Button
                        onClick={() => {
                          setTokenDialogData((prev) => ({ req_type: 'POST', req: 'api_token' }));
                          setTokenDialogPayload((prev) => ({
                            type: token_type,
                            username: '',
                            password: ''
                          }));
                          openTokenDialog();
                        }}
                        color="primary"
                      >
                        {t('api_token')}
                      </Button>
                      <Button
                        onClick={() => {
                          setTokenDialogData((prev) => ({
                            req_type: 'POST',
                            req: 'delete_api_token'
                          }));
                          setTokenDialogPayload((prev) => ({
                            type: token_type,
                            username: '',
                            password: ''
                          }));
                          openTokenDialog();
                        }}
                        color="secondary"
                      >
                        {t('delete_api_token')}
                      </Button>
                    </>
                  ))}
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    setTokenDialogData((prev) => ({ req_type: 'POST', req: 'create_api_token' }));
                    setTokenDialogPayload((prev) => ({
                      type: '',
                      username: '',
                      password: '',
                      date: ''
                    }));
                    openTokenDialog();
                  }}
                  color="primary"
                >
                  {t('create_api_token')}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        </Container>
      )}
    </React.Fragment>
  );
}

function EmailDialog({ open, handleClose, user, setUser }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const address = useAddress();
  const [values, setValues] = React.useState({ email: '' });

  const validateForm = () => {
    if (values.email) {
      fetch(address.api_url('/api/set_email'), { method: 'POST', body: values.email }).then(
        (response) => {
          if (response.ok) {
            enqueueSnackbar(t('email_change_success') + ': ' + values.email, {
              variant: 'success'
            });
            setUser({ name: user.name, email: values.email });
          } else {
            enqueueSnackbar(t('email_change_error_2') + ': ' + values.email, { variant: 'error' });
          }
        }
      );
      handleClose();
    } else {
      handleClose();
    }
  };

  const deleteEmail = () => {
    fetch(address.api_url('/api/set_email?remove'), { method: 'POST', body: '' }).then(
      (response) => {
        if (response.ok) {
          enqueueSnackbar(t('email_remove_success'), { variant: 'success' });
          setUser({ name: user.name, email: values.email });
        } else {
          enqueueSnackbar(t('email_remove_error') + ': ' + values.email, { variant: 'error' });
        }
      }
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('set_email')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="E-Mail"
          type="text"
          fullWidth
          onChange={(event) => setValues({ ...values, email: event.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={validateForm} color="primary">
          {t('set')}
        </Button>
        <Button onClick={deleteEmail} color="primary">
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function LanguageDialog({ open, handleClose }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const cur_language = localStorage.getItem('i18nextLng') || 'en';
  const address = useAddress();

  const setLanguage = () => {
    fetch(address.api_url('/api/set_language'), { method: 'POST', body: cur_language }).then(
      (response) => {
        if (response.ok) {
          window.location.reload(false);
        } else {
          enqueueSnackbar(t('language_set_error'), { variant: 'error' });
        }
      }
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('set_language')}</DialogTitle>
      <DialogContent>
        {t('set_language_confirm_1')} <i>{languageMap[cur_language].label}</i>{' '}
        {t('set_language_confirm_2')}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={setLanguage} color="primary">
          {t('set')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PasswordDialog({ open, handleClose }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const address = useAddress();
  const [password, setPassword] = React.useState(null);
  const [password_2, setPassword2] = React.useState(null);

  const validateForm = (event) => {
    if (password && password_2) {
      if (password !== password_2) {
        enqueueSnackbar(t('set_password_error_missmatch'), { variant: 'error' });
        return;
      }

      const request = { password: password };
      fetch(address.api_url('/api/set_new_password'), {
        method: 'POST',
        body: JSON.stringify(request)
      }).then((response) => {
        if (response.ok) {
          enqueueSnackbar(t('set_password_success'), { variant: 'info' });
        } else if (response.status === 401) {
          enqueueSnackbar(t('set_password_error_unauthenticated'), { variant: 'error' });
        } else if (response.status === 403) {
          enqueueSnackbar(t('set_password_error_strength'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('set_password_error_unknown'), { variant: 'error' });
        }
      });
      handleClose();
    } else {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('set_password')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="new password"
          type="password"
          fullWidth
          onChange={(event) => setPassword(event.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="serial"
          label="repeat password"
          type="password"
          fullWidth
          onChange={(event) => setPassword2(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={validateForm} color="primary">
          {t('set')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function DeleteDialog({ open, handleClose, user }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const auth = useAuth();

  const deleteAccount = () => {
    auth.deleteAccount((m) => {
      if (m === 'good') history.replace('/login');
      else enqueueSnackbar(t(m), { variant: 'error' });
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t('delete_account')}</DialogTitle>
      <DialogContent>{t('delete_account_confirm')}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={deleteAccount} color="primary">
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TokenDialogField({ id, value, data, addData }) {
  let { t } = useTranslation();

  if (id !== 'type') {
    return (
      <TextField
        autoFocus
        margin="dense"
        id="serial"
        key={id}
        value={data[id] === undefined || data[id] === '' ? value : data[id]}
        label={id === 'date' ? 'Valid for X Days' : t(id)}
        type={id === 'password' ? 'password' : id === 'date' ? 'number' : 'text'}
        fullWidth
        onChange={(event) =>
          addData(id, id === 'date' ? parseInt(event.target.value) : event.target.value)
        }
      />
    );
  } else {
    return (
      <TokenTypeSelect
        value={data[id] === undefined || data[id] === '' ? value : data[id]}
        onChange={(event) => addData(id, event.target.value)}
      />
    );
  }
}

/**
 * @param[in] params (req_type (GET, POST...); req f.e. "/api/create_api_token)
 * @param[in] payload f.e. "{'password':'', 'username':''}"
 */
function TokenDialog({ open, handleClose, params, payload }) {
  let { t } = useTranslation();
  let { enqueueSnackbar } = useSnackbar();
  const address = useAddress();
  const [data, setData] = React.useState(payload);

  // Make sure payload is acctually changed by overwriting old data.
  React.useEffect(() => {
    setData((prev) => ({ ...prev, ...payload }));
  }, [payload]);

  // Make sure data is updated and dialog is rerendered.
  const addData = (key, value) => {
    let new_data = {};
    new_data[key] = value;
    setData((prev) => ({ ...prev, ...new_data }));
  };

  const validateForm = (event) => {
    // Make sure all data is filled.
    let all_exists = true;
    for (const [key, value] of Object.entries(data)) {
      if (value === '') {
        all_exists = false;
        break;
      }
    }

    if (all_exists) {
      fetch(address.api_url('/api/' + params.req), {
        method: params.req_type,
        body: JSON.stringify(data)
      }).then((response) => {
        if (response.ok) {
          if (params.req === 'api_token') {
            response.text().then((text) => navigator.clipboard.writeText(text));
            enqueueSnackbar('api token "' + data.type + '" copied to clipboard!', {
              variant: 'success'
            });
          } else if (params.req === 'create_api_token' || params.req === 'delete_api_token') {
            window.location.reload();
          }
        } else {
          response.text().then((text) => {
            enqueueSnackbar(params.req + ': ' + response.status + ', ' + text, {
              variant: 'error'
            });
          });
        }
      });
      handleClose();
    } else {
      enqueueSnackbar('Please fill all fields!', { variant: 'error' });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{t(params.req)}</DialogTitle>
      <DialogContent>
        {Object.entries(payload).map(([key, value]) => (
          <TokenDialogField id={key} value={value} data={data} addData={addData} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={validateForm} color="primary">
          {t('set')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
