import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewListIcon from '@material-ui/icons/ViewList';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import MuiSwitch from '@material-ui/core/Switch';
import { useTranslation } from 'react-i18next';

import { IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import LanguageSelect from '../LanguageSelect';

import { statusColors } from '../Constants/constants';
import { useAuth } from '../Hooks/authHook';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import getStateManager from '../StateManager.js';

const useStyles = makeStyles({
  list: {
    width: 250
  }
});

export default function MainMenu({
  open,
  onClose,
  toggleTheme,
  currTheme,
  toggleUserMenu,
  setLang,
  lockSliderSetting,
  setLockSliderSetting
}) {
  const classes = useStyles();
  const auth = useAuth();
  let { t } = useTranslation();

  const stateManager = getStateManager();
  const controllers = stateManager.controllers;

  const zoom = function (amount) {
    let new_zoom = Number(localStorage.getItem('zoom') ?? 1) + amount;
    document.body.style.zoom = new_zoom;
    localStorage.setItem('zoom', new_zoom);
  };

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className={classes.list} role="presentation" onClick={onClose} onKeyDown={onClose}>
        <List>
          <ListItem></ListItem>

          <ListItem>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <IconButton
                  edge="end"
                  onClick={toggleUserMenu(true)}
                  aria-label="account of current user"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <LanguageSelect setLang={setLang} />
              </div>

              <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Button>
                  <ZoomInIcon onClick={() => zoom(0.2)} />
                </Button>
                <Button>
                  <ZoomOutIcon onClick={() => zoom(-0.2)} />
                </Button>
              </div>
            </div>
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <MuiSwitch checked={currTheme === 'light' ? false : true} onClick={toggleTheme} />
              }
              label="Darkmode"
            />
          </ListItem>
          <ListItem>
            Lock Slider
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button
                onClick={() => setLockSliderSetting('auto')}
                variant={lockSliderSetting == 'auto' ? 'contained' : 'outlined'}
              >
                Auto
              </Button>
              <Button
                onClick={() => setLockSliderSetting('on')}
                variant={lockSliderSetting == 'on' ? 'contained' : 'outlined'}
              >
                On
              </Button>
              <Button
                onClick={() => setLockSliderSetting('off')}
                variant={lockSliderSetting == 'off' ? 'contained' : 'outlined'}
              >
                Off
              </Button>
            </ButtonGroup>
          </ListItem>
        </List>
        {auth.user && (
          <React.Fragment>
            <Divider />
            <List>
              <ListItem button key="Controller Overview" component={Link} to="/">
                <ListItemIcon>
                  <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary={t('manage_controllers')} />
              </ListItem>
            </List>
            <Divider />
            <List>
              {controllers.map((controller) => (
                <ListItem
                  button
                  key={controller.name}
                  component={Link}
                  to={`/controller/${controller.id}`}
                >
                  <ListItemIcon>
                    <CircleIcon
                      style={{ color: statusColors[controller?.connection?.status ?? 'bad'] }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={controller.name} />
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        )}
      </div>
    </Drawer>
  );
}
