import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { App } from './App';

export default function AppWrapper({ currTheme, toggleTheme }) {
  let { t } = useTranslation();
  const notistackRef = React.useRef();
  const onClickDissmiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      ref={notistackRef}
      action={(key) => <Button onClick={onClickDissmiss(key)}>{t('dismiss')}</Button>}
    >
      <App currTheme={currTheme} toggleTheme={toggleTheme} />
    </SnackbarProvider>
  );
}
