import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next';

import { ShowSetting, ShowSettingMinMax } from '../FormWidgets/ShowSliderSetting';
import { RegTypeSelect, RegTypeHumidifierSelect } from '../FormWidgets/Select.js';
import Desc, { GetHelp, InfoButton } from '../FormWidgets/Description';
import { HandleAction } from '../Utils/utils.js';
import { LoadSettingsDialog } from '../Utils/load_settings';
import { SettingGroupGrid, ValueBox } from '../FormWidgets/SettingGroup';

import { settingControls, useSavedSettings } from './settingFunctions';
import { SettingActions } from '../components';

import getStateManager from '../StateManager.js';

export default function AdvancedSettings({ controller, parent, ...other }) {
  let { t } = useTranslation();
  let { tab_id } = useParams();

  const stateManager = getStateManager();
  const [values, setValues] = useState(parent.settings[tab_id]);
  const controls = settingControls(controller, parent, tab_id, values, setValues);
  const { openLoadSettingsDialog, closeLoadSettingsDialog } = useSavedSettings(parent, controls);

  const callAction = (type, value) => {
    if (parent.connection.status !== 'bad') {
      HandleAction(stateManager.wsManager, parent, type, value);
    }
  };

  // Interaval too check wether data should be refreshed.
  const help = GetHelp('reset_event_dates', '', controller.type);

  return (
    <React.Fragment>
      <LoadSettingsDialog
        open={openLoadSettingsDialog}
        handleClose={closeLoadSettingsDialog}
        controls={controls}
      />

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />

      <SettingGroupGrid controller={controller} id={'advanced_ventilation'}>
        <ShowSetting
          id="fan_ac_exhaust_turnoff"
          controller={controller}
          cur_value={values.fan_ac_exhaust_turnoff}
          controller_value={parent.settings.advanced.fan_ac_exhaust_turnoff}
          controls={controls}
        />
        <ShowSetting
          id="fan_ac_intake_turnoff"
          controller={controller}
          cur_value={values.fan_ac_intake_turnoff}
          controller_value={parent.settings.advanced.fan_ac_intake_turnoff}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'co2'}>
        <ShowSetting
          id="co2_min_dose_ms"
          controller={controller}
          cur_value={values.co2_min_dose_ms}
          controller_value={parent.settings.advanced.co2_min_dose_ms}
          controls={controls}
        />
        <ShowSetting
          id="hysteresis_co2"
          controller={controller}
          cur_value={values.hysteresis_co2}
          controller_value={parent.settings.advanced.hysteresis_co2}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'climate'}>
        <ShowSetting
          id="leaf_temp_diff"
          controller={controller}
          cur_value={values.leaf_temp_diff}
          controller_value={parent.settings.advanced.leaf_temp_diff}
          controls={controls}
          type="float"
        />

        <ShowSettingMinMax
          id="rh_max_with_vpd"
          show="vpd_rh_allowed"
          controller={controller}
          cur_value_max={values.rh_max_with_vpd}
          controller_value_max={parent.settings.advanced.rh_max_with_vpd}
          cur_value_min={values.rh_min_with_vpd}
          controller_value_min={parent.settings.advanced.rh_min_with_vpd}
          min={5}
          max={95}
          off_at={-1}
          controls={controls}
          type="fan_min_max"
        />

        <ShowSetting
          id="intake_sens_min_diff_rh"
          controller={controller}
          show="intake_min_diff_rh"
          cur_value={values.intake_sens_min_diff_rh}
          controller_value={parent.settings.advanced.intake_sens_min_diff_rh}
          controls={controls}
          off_at={0}
          type="float"
        />

        <ShowSetting
          id="intake_sens_min_diff_temp"
          controller={controller}
          show="intake_min_diff_temp"
          cur_value={values.intake_sens_min_diff_temp}
          controller_value={parent.settings.advanced.intake_sens_min_diff_temp}
          controls={controls}
          off_at={0}
          type="float"
        />
        <ShowSetting
          id="intake_sens_retry_wait"
          controller={controller}
          show="intake_retry_wait"
          cur_value={values.intake_sens_retry_wait}
          controller_value={parent.settings.advanced.intake_sens_retry_wait}
          controls={controls}
          off_at={0}
        />
        <ShowSetting
          id="intake_sens_retry_duration"
          controller={controller}
          show="intake_retry_durationt"
          cur_value={values.intake_sens_retry_duration}
          controller_value={parent.settings.advanced.intake_sens_retry_duration}
          controls={controls}
        />
        <ShowSetting
          id="reg_kd_temp_air"
          controller={controller}
          cur_value={values.reg_kd_temp_air}
          controller_value={parent.settings.advanced.reg_kd_temp_air}
          controls={controls}
        />
        <ShowSetting
          id="interval_ramp_temp_sp"
          controller={controller}
          cur_value={values.interval_ramp_temp_sp}
          controller_value={parent.settings.advanced.interval_ramp_temp_sp}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'humidifier'}>
        <ValueBox controller={controller} id={'advanced_reg_type_dehumidifier'}>
          <Typography gutterBottom>
            <Desc id="reg_type_humidifier" />
          </Typography>
          <RegTypeHumidifierSelect
            value={values.reg_type_humidifier}
            name="reg_type_humidifier"
            onChange={(event, value) =>
              controls.setValue('reg_type_humidifier', event.target.value)
            }
          />
        </ValueBox>
        <ShowSetting
          id="hysteresis_humidifier"
          controller={controller}
          cur_value={values.hysteresis_humidifier}
          controller_value={parent.settings.advanced.hysteresis_humidifier}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="humidifier_kd"
          controller={controller}
          cur_value={values.humidifier_kd}
          controller_value={parent.settings.advanced.humidifier_kd}
          controls={controls}
        />
        <ShowSetting
          id="offset_sp_humidifier"
          controller={controller}
          cur_value={values.offset_sp_humidifier}
          controller_value={parent.settings.advanced.offset_sp_humidifier}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="offset_sp_humidifier_night"
          controller={controller}
          cur_value={values.offset_sp_humidifier_night}
          controller_value={parent.settings.advanced.offset_sp_humidifier_night}
          controls={controls}
          type="float"
          disabled={values.offset_sp_humidifier_night === 0xFFF}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'dehumidifier'}>
        <ValueBox controller={controller} id={'advanced_reg_type_dehumidifier'}>
          <Typography gutterBottom>
            <Desc id="reg_type_dehumidifier" />
          </Typography>
          <RegTypeSelect
            value={values.reg_type_dehumidifier}
            name="reg_type_dehumidifier"
            onChange={(event, value) =>
              controls.setValue('reg_type_dehumidifier', event.target.value)
            }
          />
        </ValueBox>
        <ShowSetting
          id="hysteresis_dehumidifier"
          controller={controller}
          cur_value={values.hysteresis_dehumidifier}
          controller_value={parent.settings.advanced.hysteresis_dehumidifier}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="offset_sp_dehumidifier"
          controller={controller}
          cur_value={values.offset_sp_dehumidifier}
          controller_value={parent.settings.advanced.offset_sp_dehumidifier}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="offset_sp_dehumidifier_night"
          controller={controller}
          cur_value={values.offset_sp_dehumidifier_night}
          controller_value={parent.settings.advanced.offset_sp_dehumidifier_night}
          controls={controls}
          type="float"
          disabled={values.offset_sp_dehumidifier_night === 0xFFF}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'dehumidify_exhaust_adv_hl'}>
        <ShowSetting
          id="dehumid_exhaust_threshold"
          controller={controller}
          cur_value={values.dehumid_exhaust_threshold}
          controller_value={parent.settings.advanced.dehumid_exhaust_threshold}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="dehumid_exhaust_temp_untercut_max_allowed"
          controller={controller}
          cur_value={values.dehumid_exhaust_temp_untercut_max_allowed}
          controller_value={parent.settings.advanced.dehumid_exhaust_temp_untercut_max_allowed}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'dim_lights_adv_hl'}>
        <ShowSetting
          id="lights_dim_over_temp_hyst"
          controller={controller}
          cur_value={values.lights_dim_over_temp_hyst}
          controller_value={parent.settings.advanced.lights_dim_over_temp_hyst}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="lights_dim_over_temp_delay_sec"
          controller={controller}
          cur_value={values.lights_dim_over_temp_delay_sec}
          controller_value={parent.settings.advanced.lights_dim_over_temp_delay_sec}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'hysteresis_heater'}>
        <ShowSetting
          id="hysteresis_heater"
          controller={controller}
          cur_value={values.hysteresis_heater}
          controller_value={parent.settings.advanced.hysteresis_heater}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'hysteresis_chiller'}>
        <ShowSetting
          id="hysteresis_chiller_low"
          controller={controller}
          cur_value={values.hysteresis_chiller_low}
          controller_value={parent.settings.advanced.hysteresis_chiller_low}
          controls={controls}
          type="float"
        />
        <ShowSetting
          id="hysteresis_chiller_high"
          controller={controller}
          cur_value={values.hysteresis_chiller_high}
          controller_value={parent.settings.advanced.hysteresis_chiller_high}
          controls={controls}
          type="float"
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'neg_pressure'}>
        <ShowSetting
          id="reg_kd_pressure"
          controller={controller}
          cur_value={values.reg_kd_pressure}
          controller_value={parent.settings.advanced.reg_kd_pressure}
          controls={controls}
        />
      </SettingGroupGrid>

      <SettingGroupGrid controller={controller} id={'timing'}>
        <div>
          <Button color="primary" onClick={() => callAction('action_reset_dates_event')}>
            {t('reset_event_dates')}
          </Button>
          <InfoButton content={help.content} img={help.img} />
        </div>
      </SettingGroupGrid>

      <SettingActions onReset={controls.resetSettings} onSave={controls.saveSettings} />
    </React.Fragment>
  );
}
