import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import NHaasGroteskTxtItWoff from './fonts/ff571a3a-fb16-42b1-a691-23d8955aa35e.woff';
import NHaasGroteskTxtItWoff2 from './fonts/dc9df9ed-36b9-4522-8e57-1a899ed2c224.woff2';
import NHaasGroteskBldItWoff from './fonts/135bdd95-f711-4095-8be6-fce6d3f9ef54.woff';
import NHaasGroteskBldItWoff2 from './fonts/d1fbf511-d681-4002-b57e-cabb331b3b2e.woff2';
import NHaasGroteskBlkItWoff from './fonts/7e80bade-56f7-417a-acfe-4291ecbae208.woff';
import NHaasGroteskBlkItWoff2 from './fonts/baf627c2-ef72-493e-93cc-a8da9a1106a4.woff2';

import AppWrapper from './AppWrapper';
import bgImage from './Images/background.jpg';

const NHaasGroteskTxtIt = {
  fontFamily: 'NHaasGroteskTxtIt',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${NHaasGroteskTxtItWoff2}) format('woff2'),
    url(${NHaasGroteskTxtItWoff}) format('woff')
  `
};
const NHaasGroteskBldIt = {
  fontFamily: 'NHaasGroteskTxtIt',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    url(${NHaasGroteskBldItWoff2}) format('woff2'),
    url(${NHaasGroteskBldItWoff}) format('woff')
  `
};
const NHaasGroteskBlkIt = {
  fontFamily: 'NHaasGroteskTxtIt',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    url(${NHaasGroteskBlkItWoff2}) format('woff2'),
    url(${NHaasGroteskBlkItWoff}) format('woff')
  `
};

const themes = {
  light: function () {
    return createTheme({
      typography: {
        h1: {
          textTransform: 'uppercase'
        },
        h2: {
          textTransform: 'uppercase'
        },
        h3: {
          textTransform: 'uppercase'
        },
        h4: {
          textTransform: 'uppercase'
        },
        h5: {
          textTransform: 'uppercase'
        },
        h6: {
          textTransform: 'uppercase'
        },
        fontFamily: ['"NHaasGroteskTxtIt"', '"Helvetica"', '"Arial"', 'sans-serif'].join(', ')
      },
      palette: {
        type: 'light',
        primary: {
          main: '#95c11e'
        },
        secondary: {
          main: '#197aff'
        },
        info: {
          main: '#197aff'
        },
        success: {
          main: '#95c11e'
        },
        error: {
          main: '#ff1e00'
        },
        warning: {
          main: '#ffaf00'
        },
        text: {
          primary: '#000'
        },
        appbar_bg: 'black',
        outerContainer: {
          background: '#EDEDED'
        },
        innerContainer: {
          background: 'white',
          shadow: '#848484'
        },

        innerContainer2: {
          background: '#CCCCCC',
          shadow: '#848484'
        }
      },
      shape: {
        borderRadius: 0
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [NHaasGroteskTxtIt, NHaasGroteskBldIt, NHaasGroteskBlkIt],
            body: {
              color: 'black',
              backgroundImage: `url(${bgImage})`
            }
          }
        },
        MuiButton: {
          root: {
            textTransform: 'none'
          }
        }
      }
    });
  },

  dark: function () {
    return createTheme({
      typography: {
        h1: {
          textTransform: 'uppercase'
        },
        h2: {
          textTransform: 'uppercase'
        },
        h3: {
          textTransform: 'uppercase'
        },
        h4: {
          textTransform: 'uppercase'
        },
        h5: {
          textTransform: 'uppercase'
        },
        h6: {
          textTransform: 'uppercase'
        },
        fontFamily: ['"NHaasGroteskTxtIt"', '"Helvetica"', '"Arial"', 'sans-serif'].join(', ')
      },
      palette: {
        type: 'dark',
        primary: {
          main: '#95c11e'
        },
        secondary: {
          main: '#197aff'
        },
        info: {
          main: '#197aff'
        },
        success: {
          main: '#95c11e'
        },
        error: {
          main: '#ff1e00'
        },
        warning: {
          main: '#ffaf00'
        },
        text: {
          primary: '#FFFFFF'
          //primary: '#000000',
        },
        appbar_bg: 'black',
        outerContainer: {
          background: '#424242'
        },
        innerContainer: {
          background: '#242424',
          shadow: 'black'
        },
        innerContainer2: {
          background: '#585858',
          shadow: '#848484'
        },
        background: {
          default: '#0D1418'
        }
      },
      shape: {
        borderRadius: 0
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [NHaasGroteskTxtIt, NHaasGroteskBldIt, NHaasGroteskBlkIt],
            body: {
              color: 'white',
              backgroundImage: `none`,
              backgroundColor: `#0D1418`
            }
          }
        },
        MuiButton: {
          root: {
            textTransform: 'none'
          }
        }
      }
    });
  }
};

//console.log(theme);

export default function StyledApp({ a }) {
  const [loaded, setLoaded] = useState(false);
  const [myTheme, setMyTheme] = useState(localStorage.getItem('theme') ?? 'dark');
  useEffect(() => {
    window.document.fonts.load('10px NHaasGroteskTXW01-56It').then(() => setLoaded(true));
  }, []);

  function toggleTheme(e) {
    e.stopPropagation();
    let newTheme = myTheme === 'light' ? 'dark' : 'light';
    localStorage.setItem('theme', newTheme);
    setMyTheme(newTheme);
  }

  document.body.style.zoom = Number(localStorage.getItem('zoom') ?? 1);

  return (
    <ThemeProvider theme={themes[myTheme]()}>
      <CssBaseline />
      {loaded && (
        <React.Fragment>
          <AppWrapper currTheme={myTheme} toggleTheme={toggleTheme} />
        </React.Fragment>
      )}
    </ThemeProvider>
  );
}
